//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-272:_8988,_4008,_4108,_8844,_9900,_9036,_1804,_792;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-272')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-272', "_8988,_4008,_4108,_8844,_9900,_9036,_1804,_792");
        }
      }catch (ex) {
        console.error(ex);
      }